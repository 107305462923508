import styled from "styled-components";
import { TextField as unStyledTextField } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";

import { HeadingWithIcon } from "../../components/CalculationResultDisplay/styles";

export const TextField = styled(unStyledTextField)`
  max-width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

export const InputForm = styled.div`
  background-color: ${colors.default.white};
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
  display: flex;
  flex-basis: 328px;
  flex-direction: column;
  flex-grow: ${({ grow }) => (grow ? grow : 0)};
  flex-shrink: 1;
  padding: 16px;
  transition: opacity 1s ease-in-out, box-shadow 1.5s ease-in-out;

  > h4 {
    margin-bottom: 18px;
  }

  > div {
    margin-bottom: 20px;
  }
`;

export const UserInputSection = styled.section`
  display: flex;

  ${InputForm} + ${InputForm} {
    margin-left: 32px;
  }
`;

export const ResultsSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

export const InputFormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;

  &:last-child div {
    &:last-child {
      margin-top: 20px;
    }
  }
`;

export const InputFormSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  h4 {
    margin-bottom: 18px;
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  padding: 16px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
`;

export const FieldWithIcon = styled.div`
  position: relative;
`;

export const ActualArea = styled.div`
  border: 1px solid ${colors.default.gray};
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;

  div + div {
    margin-top: 0;
  }

  ${HeadingWithIcon}
`;

export const Header = styled.div`
  align-items: center;
  background-color: ${colors.default.white};
  display: flex;
  padding: 16px 16px 24px;
`;

export const Value = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.default.gray};
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 0;
  padding: 24px 0;
`;

export const GenericErrorMessage = styled.div`
  background-color: ${colors.default.white};
  border: 1px solid ${colors.default.red};
  box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
  color: ${colors.default.red};
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
`;

UserInputSection.displayName = "UserInputSection";
ResultsSection.displayName = "ResultsSection";
InputForm.displayName = "InputForm";
MainContainer.displayName = "MainContainer";
