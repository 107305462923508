import React from "react";
import { Span } from "@eriksdigital/atomic-ui/components";
import { BoltIcon, ToolIcon } from "@eriksdigital/atomic-ui/components/Icons";
import { T } from "../../../components/Translation";
import ErrorMessage from "../../../components/ErrorMessage";

export const gasketInputs = (gasketState = {}, errors = {}, touched = {}) => [
  {
    component: "ReactSelect",
    name: "gasketMaterial",
    label: <T>gasket.material</T>,
    placeholder: "gasket.material-placeholder",
    info: "gasket.material-info",
    errorMessage: (
      <ErrorMessage
        fieldName="gasketMaterial"
        errorList={errors["gasketMaterial"]}
        touched={touched["gasketMaterial"]}
      />
    ),
    renderOptions: (option) => {
      const [label, ...values] = option;
      const subValues = values.map((val) => {
        return { label: val, value: val };
      });
      return { label: label, options: subValues };
    },
    isSearchable: true,
    isClearable: false,
  },
  {
    component: "TextField",
    name: "outerDiameter",
    label: <T>gasket.outerDiameter</T>,
    placeholder: "gasket.outerDiameter",
    info: "gasket.outerDiameter-info",
    errorMessage: (
      <ErrorMessage
        fieldName="outerDiameter"
        errorList={errors["outerDiameter"]}
        touched={touched["outerDiameter"]}
      />
    ),
  },
  {
    component: "TextField",
    name: "innerDiameter",
    label: <T>gasket.innerDiameter</T>,
    placeholder: "gasket.innerDiameter",
    info: "gasket.innerDiameter-info",
    errorMessage: (
      <ErrorMessage
        fieldName="innerDiameter"
        errorList={errors["innerDiameter"]}
        touched={touched["innerDiameter"]}
      />
    ),
  },
  {
    component: "TextField",
    name: "holeDiameter",
    label: <T>gasket.holeDiameter</T>,
    placeholder: "gasket.holeDiameter",
    info: "gasket.holeDiameter-info",
    errorMessage: (
      <ErrorMessage
        fieldName="holeDiameter"
        errorList={errors["holeDiameter"]}
        touched={touched["holeDiameter"]}
      />
    ),
  },
];

export const boltsInputs = (boltState = {}, errors = {}, touched = {}) => [
  {
    component: "TextField",
    name: "boltNumber",
    label: <T>bolts.number</T>,
    placeholder: "bolts.number-placeholder",
    info: "bolts.number-info",
    errorMessage: (
      <ErrorMessage
        fieldName="boltNumber"
        errorList={errors["boltNumber"]}
        touched={touched["boltNumber"]}
      />
    ),
  },
  {
    component: "ReactSelect",
    name: "boltMaterial",
    label: <T>bolts.material</T>,
    withSubGroups: true,
    placeholder: "bolts.material-info",
    info: "bolts.material-placeholder",
    errorMessage: (
      <ErrorMessage
        fieldName="boltMaterial"
        errorList={errors["boltMaterial"]}
        touched={touched["boltMaterial"]}
      />
    ),
    renderOptions: (option) => {
      const [label, ...values] = option;
      const subValues = values.map((val) => {
        return { label: val, value: val };
      });
      return { label: label, options: subValues };
    },
    isSearchable: true,
    isClearable: false,
  },
  {
    component: "ReactSelect",
    name: "boltDiameter",
    label: <T>bolts.diameter</T>,
    placeholder: "bolts.diameter-info",
    info: "bolts.diameter-placeholder",
    errorMessage: (
      <ErrorMessage
        fieldName="boltDiameter"
        errorList={errors["boltDiameter"]}
        touched={touched["boltDiameter"]}
      />
    ),
    renderOptions: (option) => {
      return { label: option[0], value: option[0] };
    },
    isSearchable: false,
    isClearable: false,
  },
  {
    component: "ReactSelect",
    name: "boltLubrication",
    label: <T>bolts.lubrication-label</T>,
    info: "bolts.lubrication-info",
    renderOptions: (option) => ({ label: option, value: option }),
    isSearchable: false,
    isClearable: false,
  },
  {
    component: "ReactSelect",
    name: "calculationChoice",
    label: <T>bolts.calculate-label</T>,
    info: "bolts.calculate-info",
    renderOptions: (option) => ({ label: option, value: option }),
    isSearchable: false,
    isClearable: false,
  },
  {
    component: "TextField",
    name: "boltTorqueAmount",
    placeholder: (value) =>
      `bolts.${value.toLowerCase().replace(/\s/g, "")}-placeholder`,
    errorMessage: (
      <ErrorMessage
        fieldName="boltTorqueAmount"
        errorList={errors["boltTorqueAmount"]}
        touched={touched["boltTorqueAmount"]}
      />
    ),
  },
];

export const torqueCalculationDisplay = {
  title: <T>torque-load</T>,
  icon: ToolIcon,
  info: (
    <Span>
      <T>torque-load-info</T>
    </Span>
  ),
  values: [
    {
      label: "torque",
      path: "getGasketCalculation.torqueLoad.torque",
      unit: "Nm",
      bold: true,
      inherit: true,
    },
    {
      label: "load",
      path: "getGasketCalculation.torqueLoad.load",
      unit: "N",
      inherit: true,
    },
  ],
};

export const stressesCalculationDisplay = (errors = {}) => ({
  title: "Stresses",
  icon: BoltIcon,
  values: [
    {
      label: "stresses.yield",
      path: "getGasketCalculation.stresses.yieldService",
      unit: "MPa",
    },
    {
      label: "stresses.traction",
      path: "getGasketCalculation.stresses.traction",
      unit: "MPa",
    },
    {
      label: "stresses.traction-load-ratio",
      path: "getGasketCalculation.stresses.tractionLoadRatio",
      unit: "%",
      inherit: true,
      bold: true,
      error: errors["tractionLoadRatio"],
    },
    {
      label: "stresses.traction-torsion",
      path: "getGasketCalculation.stresses.tractionTorsion",
      unit: "MPa",
    },
    {
      label: "stresses.final-load",
      path: "getGasketCalculation.stresses.finalLoadRatio",
      unit: "%",
      bold: true,
      inherit: true,
      error: errors["finalLoadRatio"],
    },
  ],
});
