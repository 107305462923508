import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import App from "./App";

import GlobalStyles from "./styles/GlobalStyles";

const uri = "/gasket-calculator/api";

export const client = new ApolloClient({ uri, cache: new InMemoryCache() });

// eslint-disable-next-line
if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <main>
      <App />
    </main>
    <GlobalStyles />
  </ApolloProvider>,
  document.getElementById("root") || document.createElement("div")
);
