type environments = "test" | "qa" | "acc" | "prod";

const env = {
  test: "dev.",
  qa: "test.",
  acc: "test.",
  prod: "",
};

type shopNames = "nl" | "be" | "lu" | "fr" | "ch" | "uk" | "de";

const shopName = {
  nl: "shop.eriks.nl/",
  be: "shop.eriks.be/",
  lu: "shop.eriks.lu/",
  fr: "shop.eriks.fr/",
  ch: "shop.maagtechnic.ch/",
  uk: "shop.eriks.co.uk/",
  de: "shop.eriks.de/",
};

export const constructUrl = (
  environment: environments = "test",
  erpSystem: shopNames = "nl",
  language: string = "nl"
) => {
  return `https://${env[environment]}${shopName[erpSystem]}${language}`;
};
