import React, { memo } from "react";
import { H4 } from "@eriksdigital/atomic-ui/components";

import { InputForm } from "../styles";

import { InputSelector } from "../../../components/InputSelector";
import { T } from "../../../components/Translation";

import { boltsInputs } from "./StepInputs";
import sleep from "../../../utils/sleep";

const BoltsDetails = memo(
  ({
    dropdownData,
    updateFormik,
    updateFormikMulti,
    setFieldTouched,
    boltValues,
    touched,
    errors,
    boltDiametersList,
    boltMaterialsList,
  }) => {
    const updateField = async ({ currentTarget: { id, value } }) => {
      updateFormik(id, value);

      if (!touched[id]) {
        await sleep(0);
        setFieldTouched(id, true);
      }
    };

    const updateReactSelect = async (e, name) => {
      if (name === "boltDiameter") {
        const chosenBoltDiameter = boltDiametersList.find(
          (boltDiameter) => boltDiameter.id === e.value
        );

        updateFormikMulti({
          ...boltValues,
          [name]: e.value,
          boltDiameterDbo: chosenBoltDiameter.material[0].dbo,
          boltDiameterHole: chosenBoltDiameter.material[0].hole,
        });

        if (!touched[name]) {
          await sleep(0);
          setFieldTouched(name, true);
        }

        return;
      }

      if (name === "boltMaterial") {
        const chosenBoltMaterial = boltMaterialsList.find(
          (boltMaterial) => boltMaterial.materialName === e.value
        );

        updateFormikMulti({
          ...boltValues,
          [name]: e.value,
          boltMaterialMaxTemperature: chosenBoltMaterial.maxTemperature,
          boltMaterialMaxDiameter: chosenBoltMaterial.maxDiameter,
        });
        await sleep(0);

        if (!touched[name]) {
          await sleep(0);
          setFieldTouched(name, true);
        }
        return;
      }

      updateFormik(name, e.value);

      if (!touched[name]) {
        await sleep(0);
        setFieldTouched(name, true);
      }
    };

    return (
      <InputForm column data-testid={"bolts-input-form"}>
        <H4>
          <T>bolts.title</T>
        </H4>
        <InputSelector
          inputs={boltsInputs(boltValues, errors, touched)}
          errors={errors}
          touched={touched}
          dropdownData={dropdownData}
          updateField={updateField}
          updateReactSelect={updateReactSelect}
          state={boltValues}
        />
      </InputForm>
    );
  }
);

export default BoltsDetails;
