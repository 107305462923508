import React, { useContext } from "react";
import {
  LanguageContext,
  ReactSelect,
} from "@eriksdigital/atomic-ui/components";

import get from "lodash/get";

import { formatMessage } from "../Translation";

import { TextField } from "../../containers/GasketCalculator/styles";

export const InputSelector = ({
  inputs,
  errors,
  updateField,
  updateReactSelect,
  state,
  dropdownData,
  touched,
}) => {
  const { strings } = useContext(LanguageContext);

  return inputs.map(
    ({ component, name, placeholder, info, ...rest }, index) => {
      switch (component) {
        case "TextField":
          return (
            <TextField
              {...rest}
              info={formatMessage(strings, info)}
              placeholder={
                name === "boltTorqueAmount"
                  ? formatMessage(strings, placeholder(state.calculationChoice))
                  : formatMessage(strings, placeholder)
              }
              name={name}
              id={name}
              data-testid={name}
              onBlur={updateField}
              error={touched[name] && errors[name] && errors[name].length > 0}
              key={`section-${index}`}
              data-hj-whitelist
            />
          );

        case "ReactSelect":
          const withoutSubGroups = get(
            dropdownData[name],
            "boltAttributes",
            false
          );
          const items = withoutSubGroups
            ? dropdownData[name].boltAttributes
            : dropdownData[name];

          return (
            <ReactSelect
              id={name}
              options={items}
              {...rest}
              error={touched[name] && errors[name] && errors[name].length > 0}
              onChange={(e) => updateReactSelect.call(undefined, e, name)}
              value={{ label: state[name], value: state[name] }}
              key={`section-${index}`}
            />
          );
        default:
          return null;
      }
    }
  );
};
