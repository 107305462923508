/* eslint-disable import/no-anonymous-default-export */
import {
  WarningIcon,
  SuccessIcon,
  ErrorIcon,
} from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";

export default ({ initialRange, finalRange, result }) => {
  let scheme = { color: colors.default.blueD, icon: null };
  const positiveFinalRange = finalRange !== 0 ? finalRange : 200;

  // We often get initial and finalRange values as zero, which sets the range to
  // the default range, which is 0 to 200
  const hasValidRange = initialRange !== 0 && finalRange !== 0;

  if (result < initialRange || result > positiveFinalRange) {
    return { color: colors.default.red, icon: ErrorIcon };
  }

  if (result > initialRange) {
    if (
      (hasValidRange && result <= 0.1 * (finalRange - initialRange)) ||
      (!hasValidRange && result <= 0.1 * positiveFinalRange)
    ) {
      return { color: colors.default.orange, icon: WarningIcon };
    }
  }

  if (result > initialRange && result < positiveFinalRange) {
    return { color: colors.default.green, icon: SuccessIcon };
  }

  if (result === 0) {
    // Invalid calculation
    return { color: colors.default.red, icon: ErrorIcon };
  }

  return scheme;
};
