import React, { useEffect, useState, useMemo, useContext } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  LanguageContext,
  Icon,
  Button,
} from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";

import { LoadingIcon } from "@eriksdigital/atomic-ui/components/Icons";

import get from "lodash/get";

import BoltsDetails from "./Steps/BoltsDetails";
import GasketsDetails from "./Steps/GasketsDetails";
import ConditionsDetails from "./Steps/ConditionsDetails";
import StressIndicators from "../../components/StressIndicators";
import { T, formatMessage as t } from "../../components/Translation";
import { calculateStressValues } from "../../components/StressIndicators";

import { GET_ATTRIBUTES, GET_CALCULATION } from "./queries";

import {
  UserInputSection,
  ResultsSection,
  MainContainer,
  GenericErrorMessage,
} from "./styles";
import { CalculationResultDisplay } from "../../components/CalculationResultDisplay";

import {
  torqueCalculationDisplay,
  stressesCalculationDisplay,
} from "./Steps/StepInputs";
import { getStressErrors } from "../../utils/getStressErrors";
import prepareQueryVariables from "../../utils/prepareQueryVariables";

const initialValues = {
  boltNumber: "",
  boltMaterial: "",
  boltDiameter: "",
  boltLubrication: "0.08/with coating",
  calculationChoice: "Bolt load ratio",
  boltTorqueAmount: "",
  gasketMaterial: "",
  outerDiameter: "",
  innerDiameter: "",
  holeDiameter: "0",
  assemblyTemperature: "20",
  assemblyPressure: "24",
  testTemperature: "20",
  tstPressure: "",
  srvTemperature: "",
  srvPressure: "",
  srvBoltTemp: "",
  vdiCalculation: "false",
  boltMaterialMaxTemperature: "",
  boltMaterialMaxDiameter: "",
  boltDiameterDbo: "",
  boltDiameterHole: "",
  gasketMaterialMaxTemperature: "",
};

const GasketCalculator = () => {
  const { strings, language } = useContext(LanguageContext);
  const { data, error, loading } = useQuery(GET_ATTRIBUTES, {
    variables: { locale: -1 },
  });
  const attributes = useMemo(
    () => get(data, "getGasketAttributes.attributes", []),
    [data]
  );

  const dropdownData = useMemo(
    () =>
      attributes.reduce((attrs, currentAttribute) => {
        if (currentAttribute.values.materialGroups) {
          const result = currentAttribute.values.materialGroups.map((group) => {
            const materialNames = group.material.map((m) => m.materialName);

            return [group.id, ...materialNames];
          });

          attrs[currentAttribute.name] = result;
        } else {
          attrs[currentAttribute.name] = currentAttribute.values;
        }

        return attrs;
      }, {}),
    [attributes]
  );
  const dependencies = useMemo(
    () =>
      attributes.reduce((attrs, currentAttribute) => {
        if (currentAttribute.name === "gasketMaterial") {
          const gasketMaterials = currentAttribute.values.materialGroups
            .map((group) => {
              return group.material;
            })
            .reduce((acc, val) => acc.concat(val), []);
          return { ...attrs, gasketMaterials };
        }
        if (currentAttribute.name === "boltDiameter") {
          return {
            ...attrs,
            boltDiameters: currentAttribute.values.materialGroups.reduce(
              (acc, val) => acc.concat(val),
              []
            ),
          };
        }
        if (currentAttribute.name === "boltMaterial") {
          return {
            ...attrs,
            boltMaterials: currentAttribute.values.materialGroups
              .map((group) => group.material)
              .reduce((acc, val) => acc.concat(val), []),
          };
        }
        return attrs;
      }, {}),
    [attributes]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      boltNumber: yup
        .number(t(strings, "validations.numeric"))
        .required(t(strings, "validations.required")),
      boltMaterial: yup
        .string()
        .when(
          ["boltDiameterDbo", "boltMaterialMaxDiameter"],
          (boltDiameterDbo, boltMaterialMaxDiameter, schema) => {
            return boltDiameterDbo > boltMaterialMaxDiameter
              ? schema.matches(
                  "INVALID",
                  t(strings, "validations.boltDiameter-not-exist")
                )
              : schema;
          }
        )
        .required(t(strings, "validations.required")),
      boltDiameter: yup.string().required(t(strings, "validations.required")),
      boltLubrication: yup
        .string()
        .required(t(strings, "validations.required")),
      calculationChoice: yup
        .string()
        .required(t(strings, "validations.required")),
      boltTorqueAmount: yup
        .string()
        .required(t(strings, "validations.required")),
      gasketMaterial: yup.string().required(t(strings, "validations.required")),
      outerDiameter: yup
        .number(t(strings, "validations.numeric"))
        .positive(t(strings, "validations.positive"))
        .required(t(strings, "validations.required")),
      innerDiameter: yup
        .number(t(strings, "validations.numeric"))
        .positive(t(strings, "validations.positive"))
        .min(
          10,
          t(strings, "validations.greaterThanOrEqualTo", {
            minVal: 10,
          })
        )
        .when(["outerDiameter"], (outerDiameter, schema) => {
          return outerDiameter > 0
            ? schema.max(
                outerDiameter - 6,
                t(strings, "validations.lowerThan", {
                  maxVal: outerDiameter - 6,
                })
              )
            : schema;
        })
        .when(
          ["outerDiameter", "holeDiameter"],
          (outerDiameter, holeDiameter, schema) => {
            return outerDiameter > 0 && holeDiameter > 0
              ? schema.max(
                  outerDiameter - holeDiameter - 6,
                  t(strings, "validations.lowerThan", {
                    maxVal: outerDiameter - holeDiameter - 6,
                  })
                )
              : schema;
          }
        )
        .required(t(strings, "validations.required")),
      holeDiameter: yup
        .number(t(strings, "validations.numeric"))
        .min(
          0,
          t(strings, "validations.greaterThanOrEqualTo", {
            minVal: 0,
          })
        )
        .when(["boltDiameterDbo", "."], (boltDiameterDbo, current, schema) => {
          return current !== 0 && boltDiameterDbo
            ? schema.min(
                boltDiameterDbo + 1,
                t(strings, "validations.boltDiameter-too-large")
              )
            : schema;
        })
        .when(
          ["boltDiameterHole", "."],
          (boltDiameterHole, current, schema) => {
            return current !== 0 && boltDiameterHole
              ? schema.min(
                  boltDiameterHole,
                  t(strings, "validations.holeDiameter-too-small")
                )
              : schema;
          }
        ),
      assemblyTemperature: yup
        .number(t(strings, "validations.numeric"))
        .required(t(strings, "validations.required")),
      assemblyPressure: yup
        .number(t(strings, "validations.numeric"))
        .required(t(strings, "validations.required")),
      testTemperature: yup
        .number(t(strings, "validations.numeric"))
        .required(t(strings, "validations.required")),
      tstPressure: yup
        .number(t(strings, "validations.numeric"))
        .max(9999, t(strings, "validations.lowerThan", { maxVal: 9999 }))
        .required(t(strings, "validations.required")),
      srvTemperature: yup
        .number(t(strings, "validations.numeric"))
        .when(
          ["gasketMaterialMaxTemperature"],
          (gasketMaterialMaxTemperature, schema) => {
            return gasketMaterialMaxTemperature
              ? schema.max(
                  gasketMaterialMaxTemperature,
                  t(strings, "validations.lowerThan", {
                    maxVal: gasketMaterialMaxTemperature,
                  })
                )
              : schema.max(9999);
          }
        )
        .required(t(strings, "validations.required")),
      srvPressure: yup
        .number(t(strings, "validations.numeric"))
        .min(1, t(strings, "validations.greaterThan", { minVal: 1 }))
        .max(9999, t(strings, "validations.lowerThan", { maxVal: 9999 })),
      srvBoltTemp: yup
        .number(t(strings, "validations.numeric"))
        .min(20, t(strings, "validations.greaterThan", { minVal: 20 }))
        .max(9999, t(strings, "validations.lowerThan", { maxVal: 9999 }))
        .when(
          ["boltMaterialMaxTemperature"],
          (boltMaterialMaxTemperature, schema) => {
            return boltMaterialMaxTemperature
              ? schema.max(
                  boltMaterialMaxTemperature,
                  t(strings, "validations.lowerThan", {
                    maxVal: boltMaterialMaxTemperature,
                  })
                )
              : schema.max(9999);
          }
        ),
      vdiCalculation: yup.string().required(t(strings, "validations.required")),
      boltMaterialMaxTemperature: yup.number(),
      boltMaterialMaxDiameter: yup.number(),
      boltDiameterDbo: yup.number(),
      boltDiameterHole: yup.number(),
      gasketMaterialMaxTemperature: yup.number(),
    }),
  });

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const [
    getGasketCalculation,
    { called, data: calculationData },
  ] = useLazyQuery(GET_CALCULATION, {
    variables: prepareQueryVariables(formik.values),
  });

  const doCalculation = () => {
    getGasketCalculation(prepareQueryVariables(formik.values));
  };

  const [isInvalidCalculation, setCalculationValidity] = useState(false);

  const actualArea = get(
    calculationData,
    "getGasketCalculation.actualArea",
    null
  );

  useEffect(() => {
    if (called && calculationData && Object.keys(calculationData).length) {
      const stressBarValues = calculateStressValues(calculationData);

      const valueOutOfBounds = Object.keys(stressBarValues).some(
        (value) =>
          stressBarValues[value].to >= 1000 ||
          stressBarValues[value].to <= -1000
      );

      setCalculationValidity(valueOutOfBounds);
    }
  }, [calculationData, called]);

  const { finalLoadRatio, tractionLoadRatio } = getStressErrors(
    calculationData
  );
  const showStressErrorMessage = finalLoadRatio || tractionLoadRatio || false;

  const memoizedStressCalculationDisplay = useMemo(() => {
    return stressesCalculationDisplay({ finalLoadRatio, tractionLoadRatio });
  }, [finalLoadRatio, tractionLoadRatio]);

  const memoizedCalculationData = useMemo(() => {
    return calculationData;
  }, [calculationData]);

  if (loading)
    return <Icon as={LoadingIcon} size="xxl" color={colors.default.blueD} />;

  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <MainContainer>
      <UserInputSection>
        <GasketsDetails
          dropdownData={dropdownData}
          updateFormik={formik.setFieldValue}
          updateFormikMulti={formik.setValues}
          setFieldTouched={formik.setFieldTouched}
          actualArea={actualArea}
          isInvalidCalculation={isInvalidCalculation}
          gasketValues={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          gasketMaterialsList={dependencies.gasketMaterials}
        />
        <BoltsDetails
          dropdownData={dropdownData}
          updateFormik={formik.setFieldValue}
          updateFormikMulti={formik.setValues}
          setFieldTouched={formik.setFieldTouched}
          boltValues={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          boltDiametersList={dependencies.boltDiameters}
          boltMaterialsList={dependencies.boltMaterials}
        />
        <ConditionsDetails
          updateFormik={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
          conditionValues={formik.values}
          touched={formik.touched}
          errors={formik.errors}
        />
        <StressIndicators
          data={called ? memoizedCalculationData : null}
          isInvalidCalculation={isInvalidCalculation}
        >
          <Button
            id="calculate"
            style={{ marginTop: "32px" }}
            disabled={!formik.isValid}
            onClick={doCalculation}
          >
            <T>button.calculate</T>
          </Button>
        </StressIndicators>
      </UserInputSection>

      <ResultsSection>
        {isInvalidCalculation ? (
          <GenericErrorMessage data-testid="generic-error-message">
            <T>invalidCalculation</T>
          </GenericErrorMessage>
        ) : null}
        {showStressErrorMessage && (
          <GenericErrorMessage data-testid="stress-error-message">
            <T>{showStressErrorMessage.toString()}</T>
          </GenericErrorMessage>
        )}
        <CalculationResultDisplay
          template={torqueCalculationDisplay}
          data={memoizedCalculationData}
        />

        <CalculationResultDisplay
          template={memoizedStressCalculationDisplay}
          data={memoizedCalculationData}
          grow
        />
      </ResultsSection>
    </MainContainer>
  );
};

export default GasketCalculator;
